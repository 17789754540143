import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Layout, SEO, Container, Section, Divider } from "../components"
import {
  StyledIndustryChild,
  StyledIndustry,
  StyledIndustries,
} from "../components/styles/industries"
import StyledImageGallery, {
  StyledImageGalleryChild,
} from "../components/styles/services"

const Services = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ServicesImages {
      advisory_services: file(relativePath: { eq: "advisory_services.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ict_services: file(relativePath: { eq: "ict_services.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_gallery_1: file(relativePath: { eq: "image-gallery-1.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_gallery_2: file(relativePath: { eq: "image-gallery-2.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_gallery_3: file(relativePath: { eq: "image-gallery-3.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout
      location={location}
      title="We autograph every of our service with Excellence"
      subTitle="Services We Offer"
    >
      <SEO
        title="Services"
        description="Lightville excels at a lot of specialties like Sales of Business solutions and advisory services"
      />
      <StyledIndustries>
        <Section>
          <Fade bottom duration={500}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16">
                  <Img fluid={data.advisory_services.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Advisory/Consulting Services in various industries</h2>
                  <p>
                    LightVille is a hub of Premium Consultants who are a
                    collective group of experts and professionals in various
                    fields and works of life with notable results. At LightVille
                    we offer premium consulting/advisory services which is
                    mainly a support mechanism where clients strategic
                    objectives are taken by us and transformed from “As Is” to
                    “To Be” through our various strategic blueprints, expert
                    opinions and tested and trusted business principles. These
                    Advisory service cuts across industry specific.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Container>
          <Divider />
        </Container>

        <Section>
          <Fade bottom duration={500}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16 second">
                  <Img fluid={data.ict_services.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>
                    Design and Sale of Business Solution Tools/Apps (SaaS for
                    B2B, B2C){" "}
                  </h2>
                  <p>
                    At LightVille, we house software engineers and technology
                    professionals. We have over the years assessed prominent and
                    foreseeable frictions with clients and prospects and create
                    solutions to help clients win in those specific areas.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Section>
          <Fade bottom duration={500}>
            <Container>
              <div className="max-width-800">
                <p>
                  These solutions are embedded in tools such as Apps, SaaS,
                  Automated Processes, A.I, all of which are to give our Clients
                  a seamless experience in all of their engagements. These tools
                  are designed by the very best in the industry and are
                  developed in an ecosystem and atmosphere of experts found in
                  LightVille.
                </p>
                <p>Some areas our tools fix are;</p>

                <ul>
                  <li>
                    KPI monitoring system for Performance evaluations in
                    organizations
                  </li>
                  <li>
                    Compliance and Risk Management (Both internally and
                    Externally)
                  </li>
                  <li>Business Model Designs etc.</li>
                  <li>ERPs</li>
                </ul>
              </div>
            </Container>
          </Fade>
        </Section>

        <Section>
          <Fade bottom duration={500}>
            <Container>
              <StyledImageGallery>
                <StyledImageGalleryChild>
                  <Img fluid={data.img_gallery_1.childImageSharp.fluid} />
                </StyledImageGalleryChild>
                <StyledImageGalleryChild>
                  <Img fluid={data.img_gallery_2.childImageSharp.fluid} />
                </StyledImageGalleryChild>
                <StyledImageGalleryChild>
                  <Img fluid={data.img_gallery_3.childImageSharp.fluid} />
                </StyledImageGalleryChild>
              </StyledImageGallery>
            </Container>
          </Fade>
        </Section>
      </StyledIndustries>
    </Layout>
  )
}

export default Services
