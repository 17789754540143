import styled from "@emotion/styled"

import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"

const StyledImageGallery = styled(StyledFlex)`
  flex-wrap: wrap;
`

export const StyledImageGalleryChild = styled(StyledFlexChild)`
  flex: 300px;
  margin-bottom: 1em;

  @media (min-width: 759px) {
    margin: 0 0.5em 0.5em;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

export default StyledImageGallery
